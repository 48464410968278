//import Swiper from 'swiper';
import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';

// import styles bundle
import 'swiper/css/bundle';

// Swiper本体とモジュールのスタイル読み込み
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import LazyLoad from "vanilla-lazyload";
import simpleParallax from 'simple-parallax-js';


// [FULL CALENDAR]
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
//import timeGridPlugin from '@fullcalendar/timegrid';
// import momentPlugin from '@fullcalendar/moment';
//import listPlugin from '@fullcalendar/list';

//const imagesLoaded = require('imagesloaded'); //プリロード
import imagesLoaded from 'imagesloaded'; //プリロード


// [CSS]
import "../scss/app.scss";
import { applyMutationToEventStore } from '@fullcalendar/core/internal';


const body = document.body;

let device_ratio = 1;
const moblie_hreshold = 560; //mobileとの分岐width

// 言語の判別
const current_lang = document.body.classList.contains('lang_ja')? 'ja' : 'en';


const get_easing = (p) => {
  return 1 - Math.pow(1 - p, 7);
}

// 指定したY座標へのスムーススクロール
// https://www.mitsue.co.jp/knowledge/blog/frontend/201801/18_1027.html
/**
 * 
 * @param {int} goalY 
 * @param {int} startY 
 */
const smoothscroll = (goalY, startY) => {
  if(!startY) startY = 0; //頭からスクロール
  let positionY = 0; // スクロールする位置
  let progress = 0; // 現在の進捗 0 ～ 100
  
  let move = function () {
    progress++;
    positionY = goalY * get_easing(progress / 100);
    window.scrollTo(0, positionY);
    if (positionY < goalY) { // 現在位置が目的位置より進んでいなければアニメーションを続行させる
      requestAnimationFrame(move);
    }
  };
  requestAnimationFrame(move);
};


(function () {
  let body = document.body;
  let lazyLoadInstance = new LazyLoad();
  if(window.devicePixelRatio) {
    device_ratio = window.devicePixelRatio;
  }

  initPreload();
  initHeader();
  initSwiper();
  initParallax();

  if(document.getElementById('site-home')) {
    initScrollTarget();
    //trigger
    /* const boxes = document.querySelectorAll(".c-section");
    const options = {
      root: null, // viewport
      rootMargin: "-50% 0px", // centerY
      threshold: 0
    };
    const observer = new IntersectionObserver(doWhenIntersect, options);
    boxes.forEach(box => {
      observer.observe(box);
    });

    function doWhenIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          activateIndex(entry.target);
        }
      });
    }
    function activateIndex(element) {
      const currentActiveIndex = document.querySelector(".l-menu__link.is_current");
      if (currentActiveIndex !== null) {
        currentActiveIndex.classList.remove("is_current");
      }
      const newActiveIndex = document.querySelector('.l-menu__link--'+element.id);
      newActiveIndex.classList.add("is_current");
    } */

    // const image = document.querySelectorAll(".js-parallax__img");
    // new simpleParallax(image);
  }

  if(document.getElementById('site-reservation')) {
    var calendarEl = document.getElementById('calendar');
    let calendar = new Calendar(calendarEl, {
      plugins: [ googleCalendarPlugin, dayGridPlugin], // ,momentPlugin, timeGridPlugin
      googleCalendarApiKey: 'AIzaSyDr9yZYYnZikCrP0KSCc1EK81TY5G_g2Yo', //API Key
      events: {
        googleCalendarId: '9a44fa80abc7367c77717f451887a2724497a497e7e614c76c42b432fe6bb7bf@group.calendar.google.com'
      },
      initialView: 'dayGridMonth',
      /* validRange: function(nowDate) {
        return {
          start: nowDate,
          end: nowDate.clone().add(1, 'months')
        };
      }, */
      headerToolbar: {
        left: 'today',
        center: 'title',
        right: 'prev,next'
      },
      titleFormat: { year: 'numeric', month: '2-digit' },
      //titleFormat: 'MMMM D, YYYY',
      displayEventTime: false,
      eventClick: function(arg) {
        window.open(arg.event.url, 'google-calendar-event', 'width=700,height=600');
        arg.jsEvent.preventDefault()
      },
      loading: function(bool) {
        //document.getElementById('loading').style.display = bool ? 'block' : 'none';
      }
    });
    calendar.render();
  }

  if(document.getElementById('site-access')) {
    const KEY = 'AIzaSyAbtv6yc5NffzY5hir8tNk3r8nm3i9hJRY';
    const mapOptions = {
      center: {
        lat: 0,
        lng: 0,
      },
      zoom: 4,
    };

    const loader = new google.maps.plugins.loader.Loader({
      apiKey: "",
      version: "weekly",
      libraries: ["places"],
      nonce: KEY,
    });

    // Promise
    loader
      .load()
      .then((google) => {
        new google.maps.Map(document.getElementById("map"), mapOptions);
      })
      .catch((e) => {
        // do something
      });

    // Callback
    loader.loadCallback((e) => {
      if (e) {
        console.log(e);
      } else {
        // new google.maps.Map(document.getElementById("map"), mapOptions);
      }
    });

    // An error is thrown when instantiating loader with new options
    try {
      new google.maps.plugins.loader.Loader({ apiKey: "foo" });
    } catch (e) {
      console.log(e.message);
    }

    // The loader is a singleton and new loaders will resolve with the old
    const anotherLoader = new google.maps.plugins.loader.Loader(
      loader.options
    );
    anotherLoader.load().then(() => {
      console.log("another loader was used with same options");
    });
  }

})();


/**
 * pre-loading : ローディングバー
 * refer: https://hirakublog.com/code/374/
 */
function initPreload() {
  // [Loading - Progress Bar]
  const progressBar = document.querySelector('.js-loader-progress-bar')
  if(!progressBar) return;
  
  //const progressNumber = document.querySelector('.js-loader-progress-number')
  const container_element = body; //読み込み対象コンテナ
  const bgPreload_class = '.__bg-preload';
  
  const imgLoader = imagesLoaded(container_element, {background: bgPreload_class});
  const imgTotal = imgLoader.images.length; //画像の総数を取得
  
  let imgLoaded = 0;      //ロードした画像カウント
  let progressSpeed = 8;  //プログレスバーの進むスピード 大きいほど速くなる
  let progressCount = 0;  //ローディングの進捗状況 0〜100
  let progressResult = 0; //ローディングの進捗状況 0〜100
  let progressInit;

  if(!imgTotal) {
    progressBar.style.width = '100%';
    //progressNumber.innerText = 100;
    setTimeout(function () {
      body.classList.add('is-loaded');
    }, 800);
  }
  else {
    progressInit = setInterval(updateProgress, 25);
    //画像を一つロードするたびにimgLoadedのカウントをアップ
    imgLoader.on('progress', function (instance, image) {
      // console.log(image);
      // console.log('* * *');
      imgLoaded++
    })
  }
  
  //読み込み状況アップデート関数
  function updateProgress() {
    //ローディング演出用の計算
    progressCount += (imgLoaded / imgTotal) * progressSpeed;
  
    if(progressCount >= 100 && imgTotal > imgLoaded) {
      //カウントは100以上だが画像の読み込みが終わってない
      progressResult = 99;
    } else if(progressCount > 99.9) {
      //カウントが100以上になった
      progressResult = 100;
    } else {
      //現在のカウント
      progressResult = progressCount;
    }
  
    //ローディング進捗状況をプログレスバーと数字で表示
    progressBar.style.width = progressResult + '%';
    //progressNumber.innerText = Math.floor(progressResult);
    
    //ローディング完了後 0.8秒待ってページを表示
    if (progressResult >= 100 && imgTotal == imgLoaded) {
      clearInterval(progressInit);
      setTimeout(function () {
        document.querySelector('body').classList.add('is-loaded');
      }, 800);
    }
  }
}

function initParallax() {
  const para_imgs = document.querySelectorAll(".js-parallax__img");
  if(para_imgs && para_imgs.length > 0) {
    new simpleParallax(para_imgs, {
      scale: 1.1
    });
  }
}

function initScrollTarget() {
  let scrollTarget = document.querySelectorAll('.u-sctarget');
  window.addEventListener('scroll', () => {
    for (let i = 0; i < scrollTarget.length; i++) {
      const rect = scrollTarget[i].getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight; // 現在のブラウザの高さ
      if (scroll > offset - windowHeight + 150) {
        scrollTarget[i].classList.add('hide');
      }
    }
  });
}

function initSwiper() {
  if(document.getElementById('site-home')) {
    const mvSwiper = new Swiper ('.swiper-container', {
      modules: [Navigation, Pagination, EffectFade, Autoplay],
      loop: true,
      effect: "fade",
      fadeEffect: { 
        crossFade: true
      },
      speed: 900,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false,
      // }
    })
  }
  
  // [STAY - detail]
  if(document.getElementById('site-stay_detail')) {
    // [ギャラリー]
    let swiper_gallery1 = new Swiper ('.p-gallery__swiper',
    {
      modules: [Navigation, Pagination, EffectFade, Autoplay],
      loop: true,
      effect: 'fade',
      speed: 700,
      grabCursor: true,
      pagination: {
        el: '.p-gallery__rooms',
        clickable: true,
        type: 'bullets',
         renderBullet: function (index, className) {
          let _name = gallery_names[index];
          return '<button class="p-gallery__room" data-index="'+index+'">'+_name+'</button>';
        },
      },
      navigation: {
        nextEl: '.p-gallery__button-next',
        prevEl: '.p-gallery__button-prev',
      },
      on: {
        transitionStart: (swiper) => {
        }
      }
    })

    
    // [宿泊体験]
    const counter_length = document.querySelector('.p-explist__counter-length');
    const counter_current = document.querySelector('.p-explist__counter-current');
    let swiper_exp = new Swiper ('.p-explist__swiper', {
      modules: [Navigation, Pagination, Autoplay],
      loop: true,
      speed: 700,
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.p-explist__button--next',
        prevEl: '.p-explist__button--prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 60,
        }
      },
      on: { // イベントを登録する
        init: (swiper) => {
          counter_length.textContent = ("0" + swiper.slides.length).slice(-2);
        },
        slideChange: (swiper) => {
          counter_current.textContent = ("0" + (swiper.realIndex + 1)).slice(-2);
        }
      },
    });
    
  }
}


function initHeader() {
  const fixed_header = document.getElementById("siteHeader");
  // const fixed_header = document.querySelector(".l-fixmenu");
  const header_menu = document.querySelector(".l-menu");
  let pos = 0;
  let lastPos = 0;
  //const winH = window.innerHeight;
  const winH = 50;
  const docH = document.documentElement.scrollHeight;
  const windBtm = docH - winH;
  const isHome = document.body.classList.contains('home');
  

  const onScroll = () => {
    if(pos > 220) {
      fixed_header.classList.add('__show');
    }
    else {
      fixed_header.classList.remove('__show');
    }
    lastPos = pos;
  };

  const onScroll_for_home = () => {
    if(pos > winH) {
      fixed_header.classList.add('__show');
    }
    else {
      fixed_header.classList.remove('__show');
    }
    lastPos = pos;
  };
  
  let is_mobile = is_smartphone();
  if(is_mobile) {
    fixed_header.classList.add('__show')
  }
  else {
    if(document.getElementById('site-home') && !is_smartphone()) {
      window.addEventListener("scroll", () => {
        pos = window.scrollY;
        onScroll_for_home();
      });
    }
    else {
      window.addEventListener("scroll", () => {
        pos = window.scrollY;
        onScroll();
      });
    }
  }



  
  // #ナビボタン
  const navi_btn = document.getElementById('button-gmenu');
  // console.log
  navi_btn.addEventListener('click', function(ev){
    ev.preventDefault();
    if(!body.classList.contains('is-menu-open')) {
      body.classList.remove('is-menu-close');
      body.classList.add('is-menu-open');
    }
    else {
      body.classList.remove('is-menu-open');
      body.classList.add('is-menu-close');
    }
  });

  //　トップページでアンカーリンク時にメニューを閉じる
  if(document.getElementById('site-home')) {
    const menu_links = document.querySelectorAll('.l-spmenu__link');
    const menu_linksAry = Array.prototype.slice.call(menu_links); //for IE11
    menu_linksAry.forEach(function(ml) {
      if(~ml.getAttribute('href').indexOf('#')) {
        ml.addEventListener('click', function(ev){
          body.classList.remove('is-menu-open');
        });
      }
    });
  }


  // ページトップへのスムーススクロール
  // https://www.imuza.com/entry/smoothscroll
  const scroll_totop_ = () => {
    let startY = window.pageYOffset;
    const scroll = () => {
        startY = window.pageYOffset;
        let y = startY - (startY - 0) / 20 - 1;
        window.scrollTo(0, y);
        if (y < 0) return;
        requestAnimationFrame(scroll);
    };
    requestAnimationFrame(scroll);
  };
}


function initModuleTab(target_tab = document.querySelector('.mdl_tab'), target_index = 1) {
  if(!target_tab) return false;

  let tabHead = target_tab.querySelector('.mdl_tab-head');
  let tabBody = target_tab.querySelector('.mdl_tab-body');
  let tabBody_items = tabBody.querySelectorAll('.mdl_tab-body__item');

  let tab_inputs =  tabHead.querySelectorAll('input');
  let _headIndex = 1;
  let _bodyIndex = 1;

  let coverimage = document.querySelector('.sct-saijiki .cover');
  
  //body側のindex付与
  tabBody_items.forEach(tabBody_item => {
    tabBody_item.setAttribute('data-index', _bodyIndex);
    tabBody_item.classList.add('mdl_tab-body__item--index'+_bodyIndex);
    if(target_index == _bodyIndex) {
      tabBody_item.classList.add('__show-tabitem');
    }
    _bodyIndex++
  });

  //切り替えボタン
  tab_inputs.forEach(tab_input => {
    tab_input.setAttribute('data-index', _headIndex++); //head側のindex付与
    tab_input.addEventListener('change', (ev) => {
      let myindex = ev.target.getAttribute('data-index');
      tabBody_items.forEach(tabBody_item => {
        if(tabBody_item.getAttribute('data-index') != myindex) {
          tabBody_item.classList.remove('__show-tabitem')
        }
        else {
          tabBody_item.classList.add('__show-tabitem')
        }
      });
    });
  });
}


function is_smartphone() {
  if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
    return true;
  } else {
    return false;
  }
}
